import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import "./login.css";
import { auth, signInWithGoogle } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { ReactComponent as Logo } from "../../assets/img/melissaBenLogo.svg";
function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) navigate("/admin");
  }, [user, loading]);

  return (
    <>
      <img src="/src/assets/img/melissaBenLogo.svg"></img>

      <div className="login">
        <Card sx={{ maxWidth: 345 }}>
          <Logo style={{ margin: "50px 50px 0 50px" }} />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Login
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <TextField
                id="outlined-basic"
                label="E-mail Address"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ width: "-webkit-fill-available" }}
              />
              <br />
              <TextField
                id="outlined-password"
                label="Password"
                type="password"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{ width: "-webkit-fill-available" }}
              />
              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    signInWithEmailAndPassword(auth, email, password)
                  }
                  style={{ width: "-webkit-fill-available" }}
                >
                  Login
                </Button>
              </div>
              {/* <div>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={signInWithGoogle}
                  style={{ width: "-webkit-fill-available" }}
                >
                  Login with Google
                </Button>
              </div> */}
            </Typography>
          </CardContent>
          {/* <CardActions>
            <Button component={Link} to="/reset" size="small">
              Forgot password
            </Button>
            <Button component={Link} to="/register" size="small">
              Don't have an account?
            </Button>
          </CardActions> */}
        </Card>
      </div>
    </>
  );
}
export default Login;
