import * as React from "react";

import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  updateDoc,
  getDocs,
  query,
  where,
  getCountFromServer,
} from "firebase/firestore";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import { Form, Formik } from "formik";
import { string, object } from "yup";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Divider,
  FormControl,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { ReactComponent as Logo } from "../../assets/img/melissaBenLogo.svg";
import { handWriting, theme } from "../../theme";
import SendIcon from "@mui/icons-material/Send";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAsyncEffect } from "@react-hook/async";

import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import CircularProgress from "@mui/material/CircularProgress";
import DoneIcon from "@mui/icons-material/Done";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { useState } from "react";
import { Invite } from "../admin/guestList/guestList2";
import emailjs from "emailjs-com";

function Loading() {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <CircularProgress size={100} thickness={1} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Logo
          style={{
            width: "60px",
            fill: theme.palette.text.primary,
            textAlign: "center",
            display: "block",
            margin: "auto",
          }}
        />
      </Box>
    </Box>
  );
}

function Error() {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Logo
          style={{
            width: "100px",
            fill: theme.palette.text.primary,
            textAlign: "center",
            display: "block",
            margin: "auto",
          }}
        />
      </Box>
      <br />
      <Alert severity="error">
        There was an error loading your RSVP
        <br /> <a href="https://melissaben.com">Return home</a>
      </Alert>
    </Box>
  );
}

let defaultBgColor = "#657e93";

interface Fields {
  group: number;
  name: string;
  label: string;
  initialValue: any;
  type: any;
  course: Course;
}

interface GuestArray {
  guestArray: string[];
  fields: Fields[];
}

enum Course {
  Starter,
  Main,
  Dessert,
}

enum StarterOptions {
  Option1 = "House smoked chicken Caesar salad, crispy bacon, baby gem lettuce, homemade Caesar dressing, crispy onions",
  Option2 = "Stuffed portobello mushroom with parmesan and herb stuffing, balsamic glaze (Vegetarian)",
  Option3 = "Vegetarian/Vegan option",
}

enum MainOptions {
  Option1 = "Daube of beef with confit garlic and spring onion creamy potatoes, Yorkshire pudding, roast red wine gravy",
  Option2 = "Classic turkey and ham, sage and shallot stuffing, Irish pork chipolata sausage, roast turkey gravy",
  Option3 = "Vegetarian/Vegan option",
}

enum DessertOptions {
  Option1 = "Chocolate fudge cookie brownie, Belgian chocolate sauce, chocolate ice cream (Vegetarian)",
  Option2 = "Meringue and almond roulade, raspberry and white chocolate filling, raspberry sorbet (Vegetarian)",
  Option3 = "Vegan option",
}

interface IObjectKeys {
  [key: string]: string | number;
}

export default function RSVP() {
  let { id } = useParams();
  const idParam: string = id ? id : "";
  const [showForm, setShowForm] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [optionsTouched, setOptionsTouched] = React.useState(false);
  const [showOptions, setShowOptions] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [rsvpComplete, setRsvpComplete] = React.useState(false);
  const [maxAttending, setMaxAttending] = React.useState();
  const [additionalInfo, setAdditionalInfo] = React.useState<string>("");

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const db2 = getFirestore();

  const [data, setData] = useState<any>([]);
  const [rsvpCount, setRsvpCount] = useState(0);
  const [noOfPeopleInvited, setNoOfPeopleInvited] = useState(0);
  const [noOfPeopleAttending, setNoOfPeopleAttending] = useState(0);

  const starterImg = require("../../assets/img/starter.png");
  const soupImg = require("../../assets/img/soup.png");
  const mainCourseImg = require("../../assets/img/mainCourse.png");
  const dessertImg = require("../../assets/img/dessert.png");

  function sendEmail(attending: boolean) {
    emailjs.init("xRaZXnJCPgTgiEPeL");
    let noAttending = maxAttending ?? userData?.noOfAdults;
    let templateParams = {};
    if (attending) {
      templateParams = {
        guest_name: userData?.groupName,
        memorable_name: userData?.memorableName,
        rsvp_response: "yes",
        no_invited: "Number invited: " + userData?.noOfAdults,
        no_attending: "Number attending: " + noAttending,
        additional_info: "Additional information: " + additionalInfo,
      };
    } else {
      templateParams = {
        guest_name: userData?.groupName,
        rsvp_response: "no",
      };
    }

    console.log(templateParams);
    emailjs.send("service_hqovkva", "template_bj7qdc8", templateParams).then(
      function (response) {
        console.log("SUCCESS!", response.status, response.text);
      },
      function (error) {
        console.log("FAILED...", error);
      }
    );
  }

  const handleAccordianChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleSubmit = async (values: any) => {
    setSubmitting(true);
    setExpanded(false);
    const menuChoices = prettifyMenuChoices(values);
    var noAttending = maxAttending ?? userData?.noOfAdults;
    try {
      await updateDoc(doc(db2, "guests", idParam), {
        rsvp: true,
        rsvpDate: new Date(),
        attending: true,
        menuChoice: menuChoices,
        noAttending: noAttending,
        addInfo: additionalInfo,
      });
      setTimeout(() => {
        setSubmitting(false);
        setRsvpComplete(true);
      }, 1000);

      sendEmail(true);
    } catch (err) {
      console.error(err);
      setTimeout(() => {
        setSubmitting(false);
      }, 1000);
    }
  };

  const rsvpNo = async () => {
    setSubmitting(true);
    try {
      await updateDoc(doc(db2, "guests", idParam), {
        rsvp: true,
        attending: false,
      });
      // getStats();
      setTimeout(() => {
        setSubmitting(false);
        setRsvpComplete(true);
      }, 1000);

      sendEmail(false);
    } catch (err) {
      console.error(err);
      setTimeout(() => {
        setSubmitting(false);
      }, 1000);
    }
  };

  const prettifyMenuChoices = (choices: IObjectKeys) => {
    const menuChoices = [];
    var noAttending = maxAttending ?? userData?.noOfAdults;
    for (let i = 0; i < noAttending; i++) {
      const starterKey = "Starter" + i;
      const MainKey = "Main" + i;
      const DessertKey = "Dessert" + i;
      const obj = {
        Starter: choices[starterKey],
        Main: choices[MainKey],
        Dessert: choices[DessertKey],
      };
      menuChoices.push(obj);
    }
    return menuChoices;
  };

  const {
    status,
    error,
    value: userData,
  } = useAsyncEffect(async () => {
    const docRef = doc(db, "guests", idParam);

    return getDoc(docRef).then((snap) => {
      if (!snap.exists()) throw new (Error as any)("not-found"); // document missing
      console.log(snap.data());
      return snap.data();
    });
  }, []);

  let guestArray: string[] = [];
  let fields: Fields[] = [];

  const buildGuestArray = (noOfGuests: number): GuestArray => {
    guestArray = [];
    fields = [];
    for (let i = 0; i < noOfGuests; i++) {
      guestArray.push("item" + i);
      fields.push({
        group: i,
        name: "Starter" + i,
        label: "Starter" + i,
        initialValue: "",
        type: string().required(),
        course: Course.Starter,
      });
      fields.push({
        group: i,
        name: "Main" + i,
        label: "Main" + i,
        initialValue: "",
        type: string().required(),
        course: Course.Main,
      });
      fields.push({
        group: i,
        name: "Dessert" + i,
        label: "Dessert" + i,
        initialValue: "",
        type: string().required(),
        course: Course.Dessert,
      });
    }
    return { guestArray, fields };
  };

  switch (status) {
    case "success":
      buildGuestArray(userData?.noOfAdults);
  }

  const mealStatus = (errors: object, group: number, touched: object) => {
    if (Object.keys(touched).length === 0) {
      return;
    }
    if (
      "Starter" + group in errors ||
      "Main" + group in errors ||
      "Dessert" + group in errors
    ) {
      return (
        <PriorityHighIcon
          sx={{
            color: "#ff6363",
          }}
        />
      );
    }
    return (
      <DoneIcon
        sx={{
          color: "#49ab49",
        }}
      />
    );
  };

  const handleChange = (event: { target: { name: any; value: any } }) => {
    const value = event.target.value;
    buildGuestArray(value);
    setMaxAttending(value);
  };

  const renderMealChoices = () => {
    let theGuestArray: GuestArray;
    if (maxAttending) {
      theGuestArray = buildGuestArray(maxAttending);
    } else {
      theGuestArray = buildGuestArray(userData?.noOfAdults);
    }

    const initialValues = Object.fromEntries(
      theGuestArray.fields.map((field) => [field.name, field.initialValue])
    );

    const SchemaObject = Object.fromEntries(
      theGuestArray.fields.map((field) => [field.name, field.type])
    );

    const UserSchema = object().shape(SchemaObject);

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={UserSchema}
      >
        {({ errors, touched, handleSubmit, handleChange, setFieldValue }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div>
                {theGuestArray.guestArray.map((el, i) => (
                  <Accordion
                    expanded={expanded === "panel" + i}
                    onChange={handleAccordianChange("panel" + i)}
                    sx={{
                      width: {
                        xs: "100%",
                        md: "400px",
                        lg: "400px",
                      },
                      display: "table",
                      margin: "0 auto !important",
                    }}
                    key={i}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      // sx={{
                      //   backgroundColor: groupHasError(errors, i, touched)
                      //     ? "#ff9c9c"
                      //     : "",
                      // }}
                    >
                      <Typography sx={{ width: "90%", flexShrink: 0 }}>
                        {"Meal " + (i + 1)}
                      </Typography>
                      {mealStatus(errors, i, touched)}
                    </AccordionSummary>
                    <AccordionDetails>
                      {fields
                        .filter((x) => x.group === i)
                        .map(({ label, name, course }, index) => (
                          <>
                            {course === Course.Starter && (
                              <>
                                <Divider>
                                  <div>
                                    <img
                                      alt="starter icon"
                                      style={{ verticalAlign: "middle" }}
                                      width={22}
                                      src={starterImg}
                                    />
                                    &nbsp;
                                    <span>
                                      <b>Starter</b>
                                    </span>
                                  </div>
                                </Divider>
                                <RadioGroup
                                  name={name}
                                  onChange={(event) => {
                                    setFieldValue(
                                      name,
                                      event.currentTarget.value
                                    );
                                  }}
                                >
                                  <br />
                                  <FormControlLabel
                                    value={StarterOptions.Option1}
                                    control={<Radio />}
                                    label={StarterOptions.Option1}
                                  />
                                  <br />
                                  <FormControlLabel
                                    value={StarterOptions.Option2}
                                    control={<Radio />}
                                    label={StarterOptions.Option2}
                                  />
                                  <br />
                                  <FormControlLabel
                                    value={StarterOptions.Option3}
                                    control={<Radio />}
                                    label={StarterOptions.Option3}
                                  />
                                  <br />
                                </RadioGroup>
                                {touched[name] && errors[name] && (
                                  <Typography
                                    variant="subtitle1"
                                    gutterBottom
                                    sx={{ color: "#ff9c9c" }}
                                  >
                                    You must select a Starter
                                  </Typography>
                                )}

                                <Divider>
                                  <div>
                                    <img
                                      alt="starter icon"
                                      style={{ verticalAlign: "middle" }}
                                      width={22}
                                      src={soupImg}
                                    />
                                    &nbsp;
                                    <span>
                                      <b>Soup</b>
                                    </span>
                                  </div>
                                </Divider>
                                <Typography
                                  variant="body1"
                                  gutterBottom
                                  sx={{
                                    marginLeft: "32px",
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  Classic Potato & Leak
                                </Typography>
                              </>
                            )}

                            {course === Course.Main && (
                              <>
                                <Divider>
                                  <div>
                                    <img
                                      alt="starter icon"
                                      style={{ verticalAlign: "middle" }}
                                      width={22}
                                      src={mainCourseImg}
                                    />
                                    &nbsp;
                                    <span>
                                      <b>Main</b>
                                    </span>
                                  </div>
                                </Divider>
                                <RadioGroup
                                  name={name}
                                  onChange={(event) => {
                                    setFieldValue(
                                      name,
                                      event.currentTarget.value
                                    );
                                  }}
                                >
                                  <br />
                                  <FormControlLabel
                                    value={MainOptions.Option1}
                                    control={<Radio />}
                                    label={MainOptions.Option1}
                                  />
                                  <br />
                                  <FormControlLabel
                                    value={MainOptions.Option2}
                                    control={<Radio />}
                                    label={MainOptions.Option2}
                                  />
                                  <br />
                                  <FormControlLabel
                                    value={MainOptions.Option3}
                                    control={<Radio />}
                                    label={MainOptions.Option3}
                                  />
                                  <br />
                                </RadioGroup>
                                {touched[name] && errors[name] && (
                                  <Typography
                                    variant="subtitle1"
                                    gutterBottom
                                    sx={{ color: "#ff9c9c" }}
                                  >
                                    You must select a Main
                                  </Typography>
                                )}
                              </>
                            )}
                            {course === Course.Dessert && (
                              <>
                                <Divider>
                                  <div>
                                    <img
                                      alt="starter icon"
                                      style={{ verticalAlign: "middle" }}
                                      width={22}
                                      src={dessertImg}
                                    />
                                    &nbsp;
                                    <span>
                                      <b>Dessert</b>
                                    </span>
                                  </div>
                                </Divider>
                                <RadioGroup
                                  name={name}
                                  onChange={(event) => {
                                    setFieldValue(
                                      name,
                                      event.currentTarget.value
                                    );
                                  }}
                                >
                                  <br />
                                  <FormControlLabel
                                    value={DessertOptions.Option1}
                                    control={<Radio />}
                                    label={DessertOptions.Option1}
                                  />
                                  <br />
                                  <FormControlLabel
                                    value={DessertOptions.Option2}
                                    control={<Radio />}
                                    label={DessertOptions.Option2}
                                  />
                                  <br />
                                  <FormControlLabel
                                    value={DessertOptions.Option3}
                                    control={<Radio />}
                                    label={DessertOptions.Option3}
                                  />
                                  <br />
                                </RadioGroup>
                                {touched[name] && errors[name] && (
                                  <Typography
                                    variant="subtitle1"
                                    gutterBottom
                                    sx={{ color: "#ff9c9c" }}
                                  >
                                    You must select a Dessert
                                  </Typography>
                                )}
                              </>
                            )}
                          </>
                        ))}
                    </AccordionDetails>
                  </Accordion>
                ))}

                <br />
                <Box display="flex" flexDirection="column" alignItems="center">
                  {/* <Divider variant="middle" sx={{ width: 100 }} /> */}
                  <Typography
                    variant="subtitle1"
                    component="h6"
                    sx={{ marginBottom: "10px" }}
                  >
                    Is there anything additional we should know?
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <TextField
                    sx={{
                      width: {
                        xs: "100%",
                        md: "400px",
                        lg: "400px",
                      },
                    }}
                    id="add-info"
                    label="Additional Info"
                    multiline
                    rows={2}
                    variant="outlined"
                    onChange={(event) => setAdditionalInfo(event.target.value)}
                  />
                </Box>
                <br />
                <Box display="flex" flexDirection="column" alignItems="center">
                  <LoadingButton
                    color="secondary"
                    loading={submitting}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    type="submit"
                  >
                    <span>Submit</span>
                  </LoadingButton>
                </Box>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  };

  const rsvpd = userData?.rsvp;

  if (status !== "success" && loading && !error) {
    return <Loading />;
  } else if (error) {
    return <Error />;
  }
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Grid item xs={12} sm={12}>
            <Logo
              style={{
                width: "200px",
                fill: theme.palette.text.primary,
                textAlign: "center",
                display: "block",
                margin: "auto",
                cursor: "hand",
              }}
              onClick={() => {
                // eslint-disable-next-line no-restricted-globals
                parent.location = "https://melissaben.com";
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{
              marginTop: "10px",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography variant="h6" component="h6">
                September 9th, 2023
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item xs={12} sm={12}>
            <ThemeProvider theme={handWriting}>
              <Typography
                variant="h2"
                component="h2"
                style={{
                  color: theme.palette.text.primary,
                }}
              >
                {userData?.groupName}
              </Typography>
            </ThemeProvider>
          </Grid>
          {rsvpd && (
            <>
              <br />
              <Alert severity="info">You've already RSVP'd&nbsp;&nbsp;🥂</Alert>
            </>
          )}
          {rsvpComplete && (
            <>
              <br />
              <Alert severity="success">
                Thanks! Your RSVP'd&nbsp;&nbsp;🙌
              </Alert>
            </>
          )}

          {!showForm && !rsvpd && !rsvpComplete && (
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                marginTop: "10px",
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setShowForm(true);
                }}
                fullWidth
              >
                <SendIcon />
                <b>&nbsp;RSVP</b>
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
      {showForm && !rsvpd && !rsvpComplete && (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box display="flex" alignItems="center" mt={2}>
            <FormControl>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="subtitle1" component="h6">
                  Will you be attending?
                </Typography>
              </Box>
              <RadioGroup
                row
                name="row-radio-buttons-group"
                sx={{ justifyContent: "space-between" }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  onClick={() => {
                    setShowOptions(true);
                    setOptionsTouched(true);
                  }}
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  onClick={() => {
                    setShowOptions(false);
                    setOptionsTouched(true);
                  }}
                  label="No"
                />
              </RadioGroup>

              {!showOptions && optionsTouched && (
                <>
                  <br />
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <LoadingButton
                      color="secondary"
                      onClick={rsvpNo}
                      loading={submitting}
                      loadingPosition="start"
                      startIcon={<SaveIcon />}
                      variant="contained"
                    >
                      <span>Submit</span>
                    </LoadingButton>
                    {/* <Button
                      variant="contained"
                      onClick={() => {
                        rsvpNo();
                      }}
                    >
                      Submit
                    </Button> */}
                  </Box>
                </>
              )}
            </FormControl>
          </Box>
        </Box>
      )}
      {showOptions && !rsvpd && !rsvpComplete && (
        <>
          <br />

          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
              variant="subtitle1"
              component="h6"
              sx={{ marginBottom: "10px" }}
            >
              How many of you will be attending?
            </Typography>
            <Select
              id="guests-attending-select"
              value={maxAttending ?? userData?.noOfAdults}
              label="#"
              onChange={handleChange}
            >
              {guestArray.map((el, i) => (
                <MenuItem value={i + 1}>{i + 1}</MenuItem>
              ))}
            </Select>
          </Box>
          <br />
          <Box display="flex" flexDirection="column" alignItems="center">
            {/* <Divider variant="middle" sx={{ width: 100 }} /> */}
            <Typography
              variant="subtitle1"
              component="h6"
              sx={{ marginBottom: "10px" }}
            >
              Please confirm your meal choices:
            </Typography>
          </Box>
          <Box
            sx={{
              margin: "0 16px",
            }}
          >
            {renderMealChoices()}
            <br />
          </Box>
        </>
      )}
    </ThemeProvider>
  );
}
