import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";

import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { ReactComponent as Logo } from "../../assets/img/melissaBenLogo.svg";
import MenuIcon from "@mui/icons-material/Menu";
import { theme } from "../../theme";
import { ThemeProvider } from "@emotion/react";
import { MouseEventHandler } from "react";
import { logout } from "../../firebase";

const settings = ["Profile", "Account", "Dashboard", "Logout"];

export interface props {
  ScrollToAccomodation?: MouseEventHandler<any>;
  ScrollToWhenWhere?: MouseEventHandler<any>;
  showLogout?: boolean;
}

const HeaderMenu = (props: props) => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const ScrollToWhenWhereLocal = (event: any) => {
    setAnchorElNav(null);
    if (props.ScrollToWhenWhere !== undefined) {
      props.ScrollToWhenWhere(event);
    }
  };

  const ScrollToAccomodationLocal = (event: any) => {
    setAnchorElNav(null);
    if (props.ScrollToAccomodation !== undefined) {
      props.ScrollToAccomodation(event);
    }
  };

  const pages = [
    {
      name: "When and Where",
      href: "#whenWhere",
      onclick: ScrollToWhenWhereLocal,
    },
    {
      name: "Accomodation",
      href: "#accom",
      onclick: ScrollToAccomodationLocal,
    },
  ];

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static" color="default">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <Logo
                style={{
                  width: "150px",
                  marginRight: "15px",
                  fill: theme.palette.text.primary,
                }}
              />
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon color="secondary" />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem
                    key={page.name}
                    onClick={page.onclick}
                    href={page.href}
                  >
                    <Typography textAlign="center">{page.name}</Typography>
                  </MenuItem>
                ))}
                {props.showLogout && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={logout}
                  >
                    Logout
                  </Button>
                )}
              </Menu>
            </Box>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <Logo
                style={{
                  width: "100px",
                  fill: theme.palette.text.primary,
                }}
              />
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => (
                <Button
                  key={page.name}
                  onClick={page.onclick}
                  sx={{
                    my: 2,
                    color: theme.palette.text.primary,
                    display: "block",
                  }}
                >
                  {page.name}
                </Button>
              ))}
              {props.showLogout && (
                <Button variant="contained" color="secondary" onClick={logout}>
                  Logout
                </Button>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
};
export default HeaderMenu;
