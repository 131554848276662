import { useEffect } from "react";
import { auth } from "../../../firebase";
import { ThemeProvider } from "@mui/material";
import Sidebar from "../drawer/drawer";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { AdminTheme } from "../../../theme";
import MenuChoicesComponent from "../../../components/menuChoices/MenuChoices";

function Stats() {
  const [user, loading, error] = useAuthState(auth);

  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
  }, [user, loading]);

  const stats = (
    <>
      <MenuChoicesComponent />
    </>
  );

  return (
    <ThemeProvider theme={AdminTheme}>
      {!loading && <Sidebar element={stats} />}
    </ThemeProvider>
  );
}

export default Stats;
