import MUIDataTable, { MUIDataTableMeta } from "mui-datatables";
import {
  collection,
  getDocs,
  query,
  where,
  getCountFromServer,
  updateDoc,
  doc,
  getFirestore,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import { QRCode } from "react-qrcode-logo";
import logo from "../../../assets/img/melissaBenLogo.svg";
import { Button, Grid, Paper, Typography } from "@mui/material";
import emailjs from "emailjs-com";

export interface Invite {
  addInfo: string;
  attending: boolean;
  rsvp: boolean;
  noOfAdults: string;
  memorableName: string;
  noAttending: string;
  noOfChildren: string;
  menuChoice: MenuChoice[];
  rsvpDate: RsvpDate;
  groupName: string;
  id: string;
}

export interface MenuChoice {
  Main: string;
  Starter: string;
  Dessert: string;
}

export interface RsvpDate {
  seconds: number;
  nanoseconds: number;
}

interface KPIProps {
  title: string;
  value: number | string;
}

const KPI: React.FC<KPIProps> = ({ title, value }) => (
  <Paper style={{ padding: "1rem" }}>
    <Typography variant="h6">{title}</Typography>
    <Typography variant="h4">{value}</Typography>
  </Paper>
);

function GL2() {
  const [data, setData] = useState<any>([]);
  const db2 = getFirestore();
  useEffect(() => {
    const docsArr = async () => {
      const querySnapshot = await getDocs(collection(db, "guests"));
      const postData: React.SetStateAction<undefined> | { id: string }[] = [];
      querySnapshot.docs.forEach((doc) =>
        postData.push({ ...doc.data(), id: doc.id })
      );

      setData(postData);
    };

    // call the function
    docsArr()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  function buildQR(id: any) {
    return (
      <>
        <QRCode
          value={window.location.origin + "/rsvp/" + id}
          logoImage={logo}
          logoHeight={10}
          logoWidth={30}
          removeQrCodeBehindLogo={true}
          logoPadding={2}
          //   fgColor="red"
          size={100}
        />
      </>
    );
  }

  function buildLink(id: any) {
    return (
      <>
        <a
          href={window.location.origin + "/rsvp/" + id}
          target="_blank"
          rel="noopener noreferrer"
        >
          Go to RSVP page
        </a>
      </>
    );
  }

  const resetRSVP = async (id: any) => {
    try {
      // eslint-disable-next-line no-restricted-globals
      var result = confirm("Are you sure you want to reset this RSVP?");
      if (result === true) {
        await updateDoc(doc(db2, "guests", id), {
          rsvp: false,
          rsvpDate: null,
          attending: null,
          menuChoice: [],
          noAttending: null,
          addInfo: null,
        });
        alert("RSVP has been Reset!");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const columns = [
    { name: "groupName", label: "Group Name" },
    { name: "memorableName", label: "Memorable Name" },
    { name: "noOfAdults", label: "No. of Adults" },
    { name: "noOfChildren", label: "No. of Children" },
    {
      name: "id",
      label: "qrLink",
      options: {
        filter: false,
        customBodyRender: (value: any) => <>{buildLink(value)}</>,
      },
    },
    // {
    //   name: "id",
    //   label: "Link",
    //   options: {
    //     filter: false,
    //     customBodyRender: (value: any) => (
    //       <>
    //         {
    //           // buildQR(value)
    //         }
    //       </>
    //     ),
    //   },
    // },
    {
      name: "rsvp",
      label: "RSVP'd",
      options: {
        filter: false,
        customBodyRender: (value: any) => <>{value.toString()}</>,
      },
    },
    {
      name: "reset",
      label: "Reset RSVP",
      options: {
        filter: false,
        customBodyRender: (
          value: any,
          tableMeta: MUIDataTableMeta,
          updateValue: any
        ) => {
          return tableMeta.rowData[5] ? (
            <Button
              onClick={() => resetRSVP(tableMeta.rowData[4])}
              variant="outlined"
            >
              Reset RSVP
            </Button>
          ) : (
            <></>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    onTableChange: (action: any, state: any) => {
      console.log(action);
      console.dir(state);
    },
  };

  //////KPIS//////////////

  const [rsvpCount, setRsvpCount] = useState(0);
  const [noOfPeopleInvited, setNoOfPeopleInvited] = useState(0);
  const [noOfPeopleAttending, setNoOfPeopleAttending] = useState(0);
  const [noOfPeopleNotAttending, setNoOfPeopleNotAttending] = useState(0);

  const getRsvpCount = async () => {
    try {
      //   const db2 = getFirestore();
      const coll = collection(db, "guests");
      const q = query(coll, where("rsvp", "==", true));
      const snapshot = await getCountFromServer(q);
      setRsvpCount(snapshot.data().count);
    } catch (err) {
      console.error(err);
      alert(err);
    }
  };
  const generateStats = () => {
    setNoOfPeopleAttending(0);
    setNoOfPeopleNotAttending(0);
    setNoOfPeopleInvited(0);
    let noOfPeopleInvitedLocal = 0;
    let noOfPeopleAttendingLocal = 0;
    let noOfPeopleNotAttendingLocal = 0;
    data.map((item: Invite) => {
      if (item.attending) {
        noOfPeopleAttendingLocal =
          noOfPeopleAttendingLocal + Number(item.noAttending);
      } else if (item.attending == false) {
        noOfPeopleNotAttendingLocal =
          noOfPeopleNotAttendingLocal + Number(item.noOfAdults);
      }
      noOfPeopleInvitedLocal = noOfPeopleInvitedLocal + Number(item.noOfAdults);
    });
    setNoOfPeopleAttending(noOfPeopleAttendingLocal);
    setNoOfPeopleNotAttending(noOfPeopleNotAttendingLocal);
    setNoOfPeopleInvited(noOfPeopleInvitedLocal);
  };
  useEffect(() => {
    generateStats();
  }, [data]);

  getRsvpCount();

  function sendEmail() {
    emailjs.init("xRaZXnJCPgTgiEPeL");

    var templateParams = {
      guest_name: "the powers",
      rsvp_response: "yes",
      rsvp_amount: rsvpCount,
      total_invites: data.length,
      rsvp_percent: Math.round((rsvpCount / data.length) * 100),
      rsvp_no: "2",
      rsvp_yes: "1",
    };

    emailjs.send("service_hqovkva", "template_bj7qdc8", templateParams).then(
      function (response) {
        console.log("SUCCESS!", response.status, response.text);
      },
      function (error) {
        console.log("FAILED...", error);
      }
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <KPI title="Total Invites" value={data.length} />
        </Grid>
        <Grid item xs={4}>
          <KPI title="Total RSVPs" value={rsvpCount} />
        </Grid>
        <Grid item xs={4}>
          <KPI
            title="Percent RSVP'd"
            value={`${Math.round((rsvpCount / data.length) * 100)}%`}
          />
        </Grid>
        <Grid item xs={4}>
          <KPI
            title={
              "People Invited (" +
              (noOfPeopleAttending + noOfPeopleNotAttending) +
              " RSVP'd)"
            }
            value={noOfPeopleInvited}
          />
        </Grid>
        <Grid item xs={4}>
          <KPI title="People Attending" value={noOfPeopleAttending} />
        </Grid>
        <Grid item xs={4}>
          <KPI title="People Not Attending" value={noOfPeopleNotAttending} />
        </Grid>
      </Grid>
      <br />
      <MUIDataTable title={"Guest List"} data={data} columns={columns} />
    </>
  );
}
export default GL2;
