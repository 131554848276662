import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Switch,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import * as React from "react";
import HeaderMenu from "../../components/headerMenu/headerMenu";
import { ReactComponent as Logo } from "../../assets/img/melissaBenLogo.svg";
import { ReactComponent as BuildingIcon } from "../../assets/img/icons/building.svg";
import { handWriting, theme } from "../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faHotel } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as CeremonyIcon } from "../../assets/img/icons/rings.svg";
import { ReactComponent as PartyIcon } from "../../assets/img/icons/drinks.svg";
import { ReactComponent as ReceptionIcon } from "../../assets/img/icons/reception.svg";
import { ReactComponent as BeechHillLogo } from "../../assets/img/beech-hill-logo.svg";
import { ReactComponent as RingsIcon } from "../../assets/img/rings.svg";
import { hexToCSSFilter } from "hex-to-css-filter";
import Map from "../../components/map/map";
import FavoriteIcon from "@mui/icons-material/Favorite";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LanguageIcon from "@mui/icons-material/Language";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { SketchPicker } from "react-color";
import { useCallback, useEffect, useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Footer from "../../components/footer/footer";
import {
  LocationOn,
  Edit,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const rgbaToHex = (color: string): string => {
  if (/^rgb/.test(color)) {
    const rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");

    // rgb to hex
    // eslint-disable-next-line no-bitwise
    let hex = `#${(
      (1 << 24) +
      (parseInt(rgba[0], 10) << 16) +
      (parseInt(rgba[1], 10) << 8) +
      parseInt(rgba[2], 10)
    )
      .toString(16)
      .slice(1)}`;

    // added alpha param if exists
    if (rgba[4]) {
      const alpha = Math.round(0o1 * 255);
      const hexAlpha = (alpha + 0x10000).toString(16).substr(-2).toUpperCase();
      hex += hexAlpha;
    }

    return hex;
  }
  return color;
};

const Home = () => {
  const whenWhereFieldRef = React.useRef<HTMLInputElement>(null);
  const [scrollToWhenWhere, setScrollToWhenWhere] = useState(false);
  const ScrollToWhenWhere = () => {
    setScrollToWhenWhere(true);
  };

  const accFieldRef = React.useRef<HTMLInputElement>(null);
  const [scrollToAcc, setScrollToAcc] = useState(false);
  const ScrollToAccomodation = () => {
    setScrollToAcc(true);
  };

  useEffect(() => {
    if (
      scrollToWhenWhere &&
      whenWhereFieldRef !== null &&
      whenWhereFieldRef.current
    ) {
      setScrollToWhenWhere(false);
      whenWhereFieldRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (scrollToAcc && accFieldRef !== null && accFieldRef.current) {
      setScrollToAcc(false);
      accFieldRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [scrollToAcc, scrollToWhenWhere]);

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  const imageName = require("../../assets/img/main-header.jpg");
  const venueImg = require("../../assets/img/venue-sketch.jpeg");
  const marqueeImg = require("../../assets/img/marquee-sketch.jpeg");
  const barImg = require("../../assets/img/bar-sketch.jpeg");
  const melBenImg = require("../../assets/img/melBen.jpg");
  const linesImg = require("../../assets/img/lines.png");
  // let defaultBgColor = "#ffaaaa";
  let defaultBgColor = "#657e93";
  const [bgColor, setBgColor] = useState(defaultBgColor);
  const Head = styled.div`
    height: 90vh;
    background: linear-gradient(
        to bottom,
        rgba(18, 42, 66, 0.5),
        rgba(18, 42, 66, 0.5)
      ),
      url(${imageName});
    // background: url(${imageName});
    background-size: cover;
    background-position: center center !important;
  `;
  const HeadContent = styled.div`
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  `;
  const FlexBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 500px;
    height: 400px;
    align-content: space-between;
  `;
  const Item = styled.div.attrs((props: { img: any }) => props)`
    // background: linear-gradient(
    //     rgba(255, 255, 255, 0.7),
    //     rgba(255, 255, 255, 0.7)
    //   ),
    //   url(${(props) => props.img});
    background-size: cover !important;
    min-height: 250px;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    box-shadow: 0 0 10px 10px white inset;
    border-radius: 40px;
    padding: 20px;
  `;
  const Item2 = styled.div`
    padding: 20px;
    background: ${bgColor};
    color: white;
    border-radius: 10px;
  `;
  const CardContent = styled.div`
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.5);
  `;

  const Heading = styled.span`
    border-bottom: 4px solid ${bgColor};
    overflow-wrap: break-word;
  `;

  const Heading2 = styled.div`
    // border-bottom: 2px solid ${bgColor};
    min-height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-around;
    margin-bottom: 10px;
  `;

  const EntryText = styled.div`
    // background: linear-gradient(
    //     rgb(255, 170, 170, 0.7),
    //     rgb(255, 170, 170, 0.7)
    //   ),
    //   url(${melBenImg});
    // background-repeat: no-repeat !important;
    // background-position: center center !important;
    text-align: center;
    background-color: ${bgColor};
    border-radius: 10px;
    color: white;
    padding: 10px;
    margin-top: 30px;
  `;
  const SocialIconsContainer = styled.div`
    text-align: center;
  `;
  const BookingImg = styled.img`
    width: 100%;
    cursor: pointer;
  `;
  const AccTypeTitle = styled.div`
    width: 110px;
    font-weight: bold;
    display: inline-block;
  `;

  const BackToTopButton = styled.div`
    display: flex;
    position: fixed;
    bottom: 0px;
    color: white;
    right: 0;
    z-index: 1;
    margin: 20px;
    background-color: ${defaultBgColor};
    border-radius: 50%;
    cursor: pointer;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    border: 3px solid #fff;
  `;

  const LinesImg = styled.img`
    pointer-events: none;
    position: absolute;
    left: -180px;
    opacity: 0.7;
    z-index: -100;
    top: 700px;
  `;

  const cssFilter = hexToCSSFilter(rgbaToHex(theme.palette.text.primary));

  const handleChangeComplete = (color: any) => {
    setBgColor(color.hex);
  };
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [copied, setCopied] = useState(false);
  const onCopy = useCallback(() => {
    setCopied(true);
  }, []);

  return (
    <>
      <HeaderMenu
        ScrollToAccomodation={ScrollToAccomodation}
        ScrollToWhenWhere={ScrollToWhenWhere}
      />
      <BackToTopButton
        onClick={scrollToTop}
        id="backToTop"
        style={{ display: visible ? "flex" : "none" }}
      >
        <KeyboardArrowUpIcon />
      </BackToTopButton>
      <Head>
        <HeadContent>
          <ThemeProvider theme={handWriting}>
            {/* <Logo
            style={{
              width: "250px",
              fill: theme.palette.secondary.main,
              background: theme.palette.primary.main,
              padding: "20px",
            }}
          /> */}

            <Typography
              variant="h2"
              component="h2"
              style={{
                color: theme.palette.text.primary,
                // background: theme.palette.primary.main,
                padding: "20px",
                marginTop: "20px",
                marginRight: "10px",
                marginLeft: "10px",
                borderRadius: "10px",
                backgroundColor: "rgba(255, 255, 255, 0.7)",
              }}
            >
              We're getting married!
            </Typography>
            <Typography
              variant="h2"
              component="h2"
              style={{
                color: theme.palette.text.primary,
                // background: theme.palette.primary.main,
                padding: "20px",
                marginTop: "20px",
                marginRight: "10px",
                marginLeft: "10px",
                borderRadius: "10px",
                backgroundColor: "rgba(255, 255, 255, 0.7)",
              }}
            >
              09.09.23
            </Typography>
          </ThemeProvider>
        </HeadContent>
      </Head>

      {/* <SketchPicker color={bgColor} onChangeComplete={handleChangeComplete} /> */}
      <Container maxWidth="lg" style={{ textAlign: "center" }} id="whenWhere">
        {/* <LinesImg src={linesImg} /> */}
        <EntryText>
          <ThemeProvider theme={handWriting}>
            <Typography
              variant="h2"
              component="h2"
              style={{
                marginTop: "10px",
              }}
            >
              It's wedding time!
            </Typography>
          </ThemeProvider>
          <Typography
            variant="body1"
            component="div"
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              color: "white",
            }}
          >
            We can't wait to celebrate our special day with you all, we've made
            this website to provide you with some information around our day and
            some accomodation options. Roll on September 2023!
            <Typography
              variant="h6"
              component="h6"
              style={{
                marginTop: "10px",
                color: "white",
              }}
            >
              <b>Melissa & Ben&nbsp;</b>
              <FavoriteIcon style={{ verticalAlign: "middle" }} />
            </Typography>
          </Typography>
        </EntryText>
      </Container>
      <Container
        maxWidth="lg"
        style={{ textAlign: "center", marginBottom: "60px" }}
        ref={whenWhereFieldRef}
      >
        <Typography
          variant="h3"
          component="h3"
          style={{
            marginTop: "50px",
            marginBottom: "20px",
          }}
        >
          <Heading>When & where</Heading>
        </Typography>
        <a href="http://www.beech-hill.com/" target="_blank">
          <BeechHillLogo
            style={{
              height: "200px",
              filter: cssFilter.filter.replace(";", ""),
            }}
          />
        </a>
        <SocialIconsContainer>
          <a
            href="https://www.instagram.com/beechhillcountryhouse/"
            target="_blank"
            style={{ marginRight: "20px" }}
          >
            <InstagramIcon
              style={{
                color: theme.palette.text.primary,
                fontSize: "35px",
              }}
            />
          </a>

          <a href="https://www.facebook.com/beechhill/" target="_blank">
            <FacebookIcon
              style={{
                color: theme.palette.text.primary,
                fontSize: "35px",
              }}
            />
          </a>
        </SocialIconsContainer>
        {/* <Typography
          variant="h5"
          component="h5"
          style={{
            marginBottom: "50px",
          }}
        >
          It's all in one place at the Beech Hill Country House!
        </Typography> */}
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Item img={venueImg}>
                <CeremonyIcon
                  style={{
                    height: "100px",
                    filter: cssFilter.filter.replace(";", ""),
                  }}
                />
                <Typography variant="h4" component="h4">
                  The Ceremony
                </Typography>
                <Typography
                  variant="h5"
                  component="h5"
                  style={{ color: bgColor }}
                >
                  <b>2pm</b>
                </Typography>
                <Typography variant="body1" component="span">
                  Join us for a rustic woodland ceremony nestled in the forest
                  (weather permitting 🤞) just a stones throw from the house
                  itself.
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} md={4}>
              <Item img={barImg}>
                <PartyIcon
                  style={{
                    height: "100px",
                    filter: cssFilter.filter.replace(";", ""),
                  }}
                />
                <Typography variant="h4" component="h4">
                  The Drinks
                </Typography>
                <Typography
                  variant="h5"
                  component="h5"
                  style={{ color: bgColor }}
                >
                  <b>Approximately 3pm</b>
                </Typography>
                <Typography variant="body1" component="span">
                  Following the ceremony we will have a drinks reception on the
                  lawn or in the house, again, depending on the weather. (Really
                  hoping for a dry day 😬)
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} md={4}>
              <Item img={marqueeImg}>
                <ReceptionIcon
                  style={{
                    height: "100px",
                    filter: cssFilter.filter.replace(";", ""),
                  }}
                />
                <Typography variant="h4" component="h4">
                  The Reception
                </Typography>
                <Typography
                  variant="h5"
                  component="h5"
                  style={{ color: bgColor }}
                >
                  <b>Approximately 5pm</b>
                </Typography>
                <Typography variant="body1" component="span">
                  We will then move to the pavilion (this one's got a roof so
                  we're all good here) where we will dine, dance and drink our
                  way into the small hours with all our favourite people
                </Typography>
              </Item>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Map />
      <Container maxWidth="lg" id="accomodation" ref={accFieldRef}>
        <Typography
          variant="h3"
          component="h3"
          style={{
            marginTop: "50px",
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          <Heading>Accomodation</Heading>
        </Typography>
        <Typography
          variant="subtitle1"
          component="body"
          style={{
            marginBottom: "30px",
            textAlign: "center",
          }}
        >
          To book a room at the hotel visit{" "}
          <a href="https://www.beech-hill.com/accommodation" target="_blank">
            https://www.beech-hill.com/accommodation
          </a>
          <div>and book using the password below</div>
          <div>
            <b style={{ verticalAlign: "middle" }}>090923MB</b>{" "}
            <CopyToClipboard text={"090923MB"} onCopy={() => setCopied(true)}>
              <Button variant="text" color="secondary">
                {copied ? "Copied" : <ContentCopyIcon />}
              </Button>
            </CopyToClipboard>
          </div>
          {/* <TextField
            variant="outlined"
            type={showPassword ? "text" : "password"}
            style={{ marginTop: "10px", marginBottom: "10px" }}
            value="090923MB"
            disabled={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          /> */}
          <div>
            <i>
              Note: If you want more than 2 adults or children in a room, ring
              the hotel and they should be able to accomodate.
            </i>
          </div>
        </Typography>

        <Typography
          variant="subtitle1"
          component="body"
          style={{
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          If you are unable to get a room in the Hotel or would prefer to stay
          in one of the nearby hotels we recommend two nearby
        </Typography>

        <Box sx={{ flexGrow: 1, marginTop: "30px", marginBottom: "30px" }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Card
                style={{
                  border: "1px solid #c2c2c2",
                  backgroundColor: "#ededed",
                }}
              >
                <Box sx={{ p: 2, display: "flex" }}>
                  {/* <Avatar
                    variant="rounded"
                    color="secondary"
                    src="avatar1.jpg"
                  /> */}
                  <Stack spacing={0.5}>
                    <Typography
                      fontWeight={700}
                      style={{ marginBottom: "10px" }}
                    >
                      The Belfray Country Inn
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      <AccTypeTitle>
                        <LanguageIcon
                          style={{ verticalAlign: "bottom", width: "30px" }}
                        />
                        &nbsp;Website:
                      </AccTypeTitle>
                      &nbsp;
                      <a
                        href="https://thebelfraycountryinn.com/"
                        target="_blank"
                      >
                        thebelfraycountryinn.com
                      </a>
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      <AccTypeTitle>
                        <LocalPhoneIcon
                          style={{ verticalAlign: "bottom", width: "30px" }}
                        />
                        &nbsp;Phone:
                      </AccTypeTitle>
                      &nbsp;
                      <a href="tel:02871301480">02871 301480</a>
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      <AccTypeTitle>
                        <LocationOnIcon
                          style={{ verticalAlign: "bottom", width: "30px" }}
                        />
                        &nbsp;Distance:
                      </AccTypeTitle>{" "}
                      1.9 miles
                    </Typography>
                  </Stack>
                </Box>
                <Divider />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ px: 2, py: 1, bgcolor: "background.default" }}
                >
                  <a href="http://www.booking.com/Share-eJF5ES" target="_blank">
                    <Chip label="booking.com" style={{ cursor: "pointer" }} />
                  </a>

                  {/* <Switch /> */}
                </Stack>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                style={{
                  border: "1px solid #c2c2c2",
                  backgroundColor: "#ededed",
                }}
              >
                <Box sx={{ p: 2, display: "flex" }}>
                  {/* <Avatar
                    variant="rounded"
                    color="secondary"
                    src="avatar1.jpg"
                  /> */}
                  <Stack spacing={0.5}>
                    <Typography
                      fontWeight={700}
                      style={{ marginBottom: "10px" }}
                    >
                      Premier Inn
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      <AccTypeTitle>
                        <LanguageIcon
                          style={{ verticalAlign: "bottom", width: "30px" }}
                        />
                        &nbsp;Website:
                      </AccTypeTitle>
                      &nbsp;
                      <a
                        href="https://www.premierinn.com/gb/en/hotels/northern-ireland/londonderry/derry-londonderry/derry-londonderry.html?cid=GLBC_LONCRE"
                        target="_blank"
                      >
                        premierinn.com
                      </a>
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      <AccTypeTitle>
                        <LocalPhoneIcon
                          style={{ verticalAlign: "bottom", width: "30px" }}
                        />
                        &nbsp;Phone:
                      </AccTypeTitle>
                      &nbsp;
                      <a href="tel:03333219303">0333 321 9303</a>
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      <AccTypeTitle>
                        <LocationOnIcon
                          style={{ verticalAlign: "bottom", width: "30px" }}
                        />
                        &nbsp;Distance:
                      </AccTypeTitle>{" "}
                      2.8 miles
                    </Typography>
                  </Stack>
                </Box>
                <Divider />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ px: 2, py: 1, bgcolor: "background.default" }}
                >
                  <a
                    href="https://www.trivago.co.uk/?iRoomType=7&aRooms%5B0%5D%5Badults%5D=2&cpt2=2549186%2F100&hasList=1&hasMap=0&sharedcid=2549186&tab=deals&ra="
                    target="_blank"
                  >
                    <Chip label="trivago.com" style={{ cursor: "pointer" }} />
                  </a>

                  {/* <Switch /> */}
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Typography
          variant="subtitle1"
          component="body"
          style={{
            marginTop: "10px",
            textAlign: "center",
          }}
        >
          There are also plenty of nearby Airbnb's available:&nbsp;
          <a
            href="https://www.airbnb.co.uk/s/Drumahoe--Londonderry--UK/homes?tab_id=home_tab&refinement_paths%5B%5D=%2Fhomes&flexible_trip_lengths%5B%5D=one_week&place_id=ChIJTRIpn9vhX0gRPmiDh2b3Zv8&date_picker_type=calendar&checkin=2023-09-09&checkout=2023-09-10&source=structured_search_input_header&search_type=autocomplete_click"
            target="_blank"
          >
            airbnb.com
          </a>
        </Typography>
      </Container>
      <Container
        maxWidth="lg"
        style={{ textAlign: "center", marginTop: "70px", marginBottom: "70px" }}
      >
        <RingsIcon
          style={{
            height: "100px",
          }}
        />
      </Container>
      <Footer />
    </>
  );
};
export default Home;
