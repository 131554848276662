import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { Chip } from "@mui/material";

type MenuItem = {
  Starter?: string;
  Main?: string;
  Dessert?: string;
};

type Counts = {
  starters: { [type: string]: number };
  mains: { [type: string]: number };
  desserts: { [type: string]: number };
};

const MenuChoicesComponent = () => {
  const [menuChoices, setMenuChoices] = useState<MenuItem[][]>([]);
  const [menuChoiceCounts, setMenuChoiceCounts] = useState<Counts>({
    starters: {},
    mains: {},
    desserts: {},
  });

  useEffect(() => {
    const fetchMenuChoices = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "guests"));
        let menuChoicesData = querySnapshot.docs.map(
          (doc) => doc.data().menuChoice
        );
        menuChoicesData = menuChoicesData.filter(function (element) {
          return element !== undefined;
        });
        setMenuChoices(menuChoicesData);
      } catch (error) {
        console.error("Error fetching menu choices:", error);
      }
    };

    fetchMenuChoices();
  }, []);

  useEffect(() => {
    const countMenuChoices = () => {
      let count: Counts = {
        starters: {},
        mains: {},
        desserts: {},
      };

      menuChoices.forEach((menuItems) => {
        menuItems.forEach((menuItem) => {
          if (menuItem.Starter) {
            if (count.starters[menuItem.Starter]) {
              count.starters[menuItem.Starter]++;
            } else {
              count.starters[menuItem.Starter] = 1;
            }
          }
          if (menuItem.Main) {
            if (count.mains[menuItem.Main]) {
              count.mains[menuItem.Main]++;
            } else {
              count.mains[menuItem.Main] = 1;
            }
          }
          if (menuItem.Dessert) {
            if (count.desserts[menuItem.Dessert]) {
              count.desserts[menuItem.Dessert]++;
            } else {
              count.desserts[menuItem.Dessert] = 1;
            }
          }
        });
      });

      setMenuChoiceCounts(count);
    };

    countMenuChoices();
  }, [menuChoices]);

  return (
    <div>
      <h3>Menu Choices</h3>
      <div>
        <h4>Starters:</h4>
        <ul>
          {Object.entries(menuChoiceCounts.starters).map(([type, count]) => (
            <div key={type}>
              <Chip label={count} /> &nbsp;{type}
            </div>
          ))}
        </ul>
      </div>
      <div>
        <h4>Mains:</h4>
        <ul>
          {Object.entries(menuChoiceCounts.mains).map(([type, count]) => (
            <div key={type}>
              <Chip label={count} /> &nbsp;{type}
            </div>
          ))}
        </ul>
      </div>
      <div>
        <h4>Desserts:</h4>
        <ul>
          {Object.entries(menuChoiceCounts.desserts).map(([type, count]) => (
            <div key={type}>
              <Chip label={count} /> &nbsp;{type}
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MenuChoicesComponent;
