import { Firestore, addDoc, collection } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { auth, db } from "../../../firebase";
import {
  Button,
  Snackbar,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Sidebar from "../drawer/drawer";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { AdminTheme } from "../../../theme";
import AddIcon from "@mui/icons-material/Add";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import guestJsonData from "../../../data/guests.json";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export interface addGuestProps {}
export interface guest {
  groupName: string;
  memorableName: string;
  noOfAdults: number;
  noOfChildren: string;
}
function AddGuest({}: addGuestProps) {
  const [inputs, setInputs] = useState<any>({});
  const [user, loading, error] = useAuthState(auth);
  const [open, setOpen] = React.useState(false);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const navigate = useNavigate();
  const handleChange = (event: { target: { name: any; value: any } }) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values: any) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    try {
      await addDoc(collection(db, "guests"), {
        ...inputs,
        rsvp: false,
      });
      setOpen(true);
      setInputs({});
    } catch (err) {
      console.error(err);
      alert(err);
    }
  };

  const addAllGuests = async () => {
    let guestsJSONArray: {
      groupName: any;
      memorableName: any;
      noOfAdults: any;
      noOfChildren: any;
      rsvp: boolean;
    }[] = [];
    const data = guestJsonData.data;
    data.forEach((row: any) => {
      guestsJSONArray.push({
        groupName: row[1],
        memorableName: row[0],
        noOfAdults: Number(row[2]),
        noOfChildren: Number(row[3]),
        rsvp: false,
      });
    });
    await guestsJSONArray.forEach(async (guest) => {
      try {
        await addDoc(collection(db, "guests"), guest);
        console.log(guest);
      } catch (err) {
        console.error(err);
        alert(err);
      }
    });
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
  }, [user, loading]);

  const addGuestForm = (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Guest added
        </Alert>
      </Snackbar>
      <Typography variant="h2" gutterBottom>
        Add Guest
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          id="outlined-basic"
          label="Group Name (Eg. The Powers)"
          variant="outlined"
          type="text"
          name="groupName"
          value={inputs.groupName || ""}
          onChange={handleChange}
          required={true}
        />
        <br />
        <br />
        <TextField
          id="outlined-basic"
          label="Memorable Name (Eg. Ben & Mel)"
          variant="outlined"
          type="text"
          name="memorableName"
          value={inputs.memorableName || ""}
          onChange={handleChange}
          required={true}
        />
        <br />
        <br />
        <TextField
          id="outlined-basic"
          label="No. of adults"
          variant="outlined"
          type="number"
          name="noOfAdults"
          value={inputs.noOfAdults || ""}
          onChange={handleChange}
          required={true}
        />
        <br />
        <br />
        <TextField
          id="outlined-basic"
          label="No. of children"
          variant="outlined"
          type="number"
          name="noOfChildren"
          value={inputs.noOfChildren || ""}
          onChange={handleChange}
          required={true}
        />
        <br />
        <br />
        <Button
          color="secondary"
          variant="contained"
          size="large"
          type="submit"
        >
          <AddIcon />
          &nbsp;Add
        </Button>
      </form>
      {/* <Button
        color="secondary"
        variant="contained"
        size="large"
        onClick={addAllGuests}
      >
        <AddIcon />
        &nbsp;Add all guests
      </Button> */}
    </>
  );

  return (
    <ThemeProvider theme={AdminTheme}>
      {!loading && <Sidebar element={addGuestForm} />}
    </ThemeProvider>
  );
}

export default AddGuest;
