import React from "react";
import "./marker.css";

const Marker = (props: any) => {
  const { color, name, id } = props;
  const heartMarker = require("../../assets/img/icons/heart.png");
  const markerClicked = () => {
    window.location.href =
      "https://www.google.com/maps/place/Beech+Hill+Country+House/@54.9692291,-7.2701371,15z/data=!4m8!3m7!1s0x0:0x29f905f0f0d8af77!5m2!4m1!1i2!8m2!3d54.9692097!4d-7.2701503";
  };
  return (
    <div
      id="heart"
      className="marker"
      style={{
        cursor: "pointer",
        background: `url(${heartMarker})`,
      }}
      title={name}
      onClick={markerClicked}
    />
  );
};

export default Marker;
