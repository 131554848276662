import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import Marker from "../marker/marker";

const Map = (props: any) => {
  const lat = 54.9691211;
  const lng = -7.2702139;
  const [center, setCenter] = useState({ lat: lat, lng: lng });
  const [zoom, setZoom] = useState(15);

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "" }}
        defaultCenter={center}
        defaultZoom={zoom}
        options={{
          styles: require(`../../assets/json/map-style-blue.json`),
        }}
      >
        <Marker lat={lat} lng={lng} name="My Marker" color="blue" />
      </GoogleMapReact>
    </div>
  );
};

export default Map;
