import GL2 from "../guestList/guestList2";

function Dashboard() {
  return (
    <div>
      <GL2 />
    </div>
  );
}
export default Dashboard;
