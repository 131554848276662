import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "./admin.css";
import { auth, db } from "../../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import { ThemeProvider } from "@mui/material";
import { AdminTheme } from "../../theme";
import Sidebar from "./drawer/drawer";
import Dashboard from "./components/dashboard";

function Admin() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const fetchUserName = async () => {
    try {
      const q = query(
        collection(db, "users"),
        where("email", "==", user?.email)
      );
      console.log(q);
      const doc = await getDocs(q);
      console.log(doc);

      const data = doc.docs[0].data();
      console.log(data);

      setName(data.name);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
    fetchUserName();
  }, [user, loading]);

  return (
    <ThemeProvider theme={AdminTheme}>
      {!loading && <Sidebar element={<Dashboard />} />}
    </ThemeProvider>
  );
}
export default Admin;
