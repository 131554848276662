/* eslint-disable no-lone-blocks */
import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { auth, db } from "../../../firebase";
import { QRCode } from "react-qrcode-logo";
import logo from "../../../assets/img/melissaBenLogo.svg";
import { Grid, styled, Typography } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

const MyComponent = styled("div")({
  width: 300,
  height: 220,
  border: "1px dashed grey",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
});
const Wrapper = styled("div")({
  display: "inline-block",
  margin: 5,
});

function QRCodes() {
  const [data, setData] = useState<any>([]);
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    const docsArr = async () => {
      const querySnapshot = await getDocs(collection(db, "guests"));
      const postData: React.SetStateAction<undefined> | { id: string }[] = [];
      querySnapshot.docs.forEach((doc) =>
        postData.push({ ...doc.data(), id: doc.id })
      );
      //   console.log(JSON.stringify(postData));
      setData(postData);
    };

    // call the function
    docsArr()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
  }, [user, loading]);

  function buildQR(id: any) {
    return (
      <>
        <QRCode
          value={window.location.origin + "/rsvp/" + id}
          // logoImage={logo}
          // logoHeight={10}
          // logoWidth={30}
          // removeQrCodeBehindLogo={true}
          // logoPadding={2}
          fgColor="red"
          size={300}
        />
      </>
    );
  }

  return (
    <>
      {data.map((item: any, i: any) => {
        {
          return (
            <Wrapper>
              <MyComponent>
                <Typography
                  variant="body1"
                  component="span"
                  sx={{ marginBottom: "5px" }}
                >
                  Scan me with your phone camera
                </Typography>
                <QRCode
                  value={window.location.origin + "/rsvp/" + item.id}
                  logoImage={logo}
                  logoHeight={10}
                  logoWidth={30}
                  removeQrCodeBehindLogo={true}
                  logoPadding={2}
                  fgColor="#6c6c6c"
                  size={130}
                />

                <Typography
                  variant="h6"
                  component="h6"
                  sx={{ marginBottom: "10px" }}
                >
                  {item.groupName}
                </Typography>
              </MyComponent>
              <Typography variant="subtitle1" component="h6">
                Memorable name: {item.memorableName}
              </Typography>
            </Wrapper>
          );
        }
      })}
    </>
  );
}
export default QRCodes;
